export const MIN_NUMBER_OF_ADULTS = 1;
export const MAX_NUMBER_OF_ADULTS = 9;
export const MIN_NUMBER_OF_CHILDREN = 0;
export const MAX_NUMBER_OF_CHILDREN = 9;
export const MIN_CHILD_AGE = 0;
export const MAX_CHILD_AGE = 11;

export const DATE_FORMAT = "yyyy-MM-dd";

export const DEFAULT_ROOMS = [
  {
    adults: 2,
    childrenAge: [],
  },
];
