import { isValid, parse } from "date-fns";
import { HotelLocationType } from "../enums/HotelLocationType";
import { HotelsSearchParamsRoom } from "../types/HotelsSearchParams";
import { DATE_FORMAT } from "../constants/hotelConstants";

interface ParsableRoom {
  adults: string;
  childrenAge?: string | string[];
}

export const parseDateFromQueryParam = (date: any): Date | undefined => {
  const hasValidType = typeof date === "string" || date instanceof Date;
  if (!date || !hasValidType) return undefined;
  const dateInDateFormat = typeof date === "string" ? parse(date, DATE_FORMAT, new Date()) : date;
  return isValid(dateInDateFormat) ? dateInDateFormat : undefined;
};

export const parseLocationCodeFromQueryParam = (locationCode: any): string | undefined => {
  return !locationCode || typeof locationCode !== "string" ? undefined : locationCode;
};

export const parseLocationTypeFromQueryParam = (
  locationType: any
): HotelLocationType | undefined => {
  if (!locationType || typeof locationType !== "string") return undefined;
  const values = Object.values(HotelLocationType).map((locType) => locType.toLowerCase());
  return values.includes(locationType.toLowerCase())
    ? (locationType as HotelLocationType)
    : undefined;
};

const isStringOrNumber = (val: any): boolean => {
  return typeof val === "string" || typeof val === "number";
};

const isNumeric = (val: any): boolean => {
  return isStringOrNumber(val) && !Number.isNaN(Number(val));
};

const areChildrenValid = (children: any): boolean => {
  if (!children) return true;
  if (Array.isArray(children)) {
    return children.every(isNumeric);
  } else if (typeof children === "string") {
    const splitted = children.split(",");
    return splitted.every(isNumeric);
  }
  return false;
};

const areRoomsValidToMap = (rooms: any): rooms is ParsableRoom[] => {
  return (
    Array.isArray(rooms) &&
    (rooms as any[]).every((room) => {
      const isAdultValid = !!room.adults && isNumeric(room.adults);
      const areChildrenAgeValid = areChildrenValid(room.childrenAge);
      return isAdultValid && areChildrenAgeValid;
    })
  );
};

export const parseRoomsFromQueryParam = (rooms: any): HotelsSearchParamsRoom[] | undefined => {
  if (!rooms || !areRoomsValidToMap(rooms)) return undefined;
  return rooms.map((room) => ({
    adults: Number(room.adults),
    childrenAge: !room.childrenAge
      ? []
      : Array.isArray(room.childrenAge)
      ? room.childrenAge.map(Number)
      : room.childrenAge.split(",").map(Number),
  }));
};
